import { createSelector } from "@reduxjs/toolkit"

import { type Model, defaultDocumentId } from "core/modules/state/model/Model"

export const createAppSelector = createSelector.withTypes<Model>()

const selectType = (_state: unknown, type: string) => type
const selectId = (_state: unknown, _type: string, id?: string | number) => id
const selectIds = (_state: unknown, _type: string, ids?: (string | number)[]) => ids
const selectDocuments = (state: Model) => state.documents

const selectDefaultDocument = createAppSelector(
  [selectDocuments, selectType],
  (documents, type) => documents[type]?.dictionary?.[defaultDocumentId]
)

const selectDocument = createAppSelector([selectDocuments, selectType, selectId], (documents, type, id) =>
  id ? documents[type]?.dictionary?.[id] : undefined
)

const selectMultipleDocuments = createAppSelector([selectDocuments, selectType, selectIds], (documents, type, ids) => {
  if (!ids?.length) return []

  return ids.map(id => documents[type]?.dictionary?.[id]).filter(document => !!document)
})

export { selectDefaultDocument, selectDocument, selectMultipleDocuments as selectDocuments }
