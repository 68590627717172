import { defaultDocumentId } from "core/modules/state/model/Model"
import { selectDefaultDocument } from "core/modules/store/selectors"
import { useAppDispatch, useAppSelector } from "core/modules/store/storeHooks"
import { useCallback } from "react"

export const useDefaultDocument = <T>(type: string): [T, (document: T) => void] => {
  const dispatch = useAppDispatch()

  const defaultDocument = useAppSelector<T>(state => selectDefaultDocument(state, type))

  const setDefaultDocument = useCallback(
    (document: T) => {
      dispatch({ type: "SetDocumentLocal", document: { ...document, id: defaultDocumentId } })
    },
    [dispatch]
  )

  return [defaultDocument, setDefaultDocument]
}
