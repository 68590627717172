import type { Question } from "app/surveys/modules/state/model/Model"
import type { Localization } from "core/modules/localization/Localization"
import type { Theme } from "core/modules/themes/Theme"
import { getOptionColors } from "./WellbeingAggregateReportUtils"

export const CategoryPrefix = {
  BIOMECHANICS: "bm",
  CORE: "co",
  FEEDBACK: "fe",
  GENERAL_HEALTH: "gh",
  GOAL_MOTIVATION: "gm",
  LIFE_SATISFACTION: "ls",
  MENTAL_ENERGY: "me",
  NUTRITION: "nu",
  PHYSICAL_ACTIVITY: "pa",
  SLEEP_AND_RECOVERY: "sr",
  WELLBEING_AT_WORK: "ww",
  WORK_PERFORMANCE: "wp",
  WELLBEING_GOAL_AND_CHANGE: "wc"
}

export const COBL_CATEGORIES = [
  CategoryPrefix.GENERAL_HEALTH,
  CategoryPrefix.PHYSICAL_ACTIVITY,
  CategoryPrefix.NUTRITION,
  CategoryPrefix.MENTAL_ENERGY,
  CategoryPrefix.BIOMECHANICS,
  CategoryPrefix.SLEEP_AND_RECOVERY,
  CategoryPrefix.CORE
]

export const RatingLimit = {
  IMPROVE: 2,
  FOCUS: 2.5,
  NEUTRAL: 3.5,
  GOOD: 4.5,
  EXCELLENT: 5.1
}

// Follow-up identifying question used until we have different survey for follow-ups
export const followUpIdentifyingQuestion = "wc_goal"

export const RATING_LIMITS = Object.values(RatingLimit)

export const ratingIndex = (score: number) => RATING_LIMITS.findIndex(limit => score < limit)

export const getValueColor = (value: number | undefined, theme: Theme) => {
  if (value === undefined) return getOptionColors(theme)[2]

  value = Math.round(value * 10) / 10

  if (value < RatingLimit.IMPROVE) {
    return getOptionColors(theme)[4]
  } else if (value < RatingLimit.FOCUS) {
    return getOptionColors(theme)[3]
  } else if (value < RatingLimit.NEUTRAL) {
    return getOptionColors(theme)[2]
  } else if (value < RatingLimit.GOOD) {
    return getOptionColors(theme)[1]
  } else {
    return getOptionColors(theme)[0]
  }
}

export const getQuestionCoblCategroyPrefix = <T extends Question>(question: T | undefined) =>
  COBL_CATEGORIES.find(prefix => question?.key?.startsWith(prefix))

export const getValueDescription = (value: number | undefined, localization: Localization, appName: string): string => {
  if (value === undefined) return ""

  value = Math.round(value * 10) / 10

  if (value < RatingLimit.IMPROVE) {
    return localization.txt(appName, "report", "common", "results", "poor")
  } else if (value < RatingLimit.FOCUS) {
    return localization.txt(appName, "report", "common", "results", "satisfactory")
  } else if (value < RatingLimit.NEUTRAL) {
    return localization.txt(appName, "report", "common", "results", "fair")
  } else if (value < RatingLimit.GOOD) {
    return localization.txt(appName, "report", "common", "results", "good")
  } else {
    return localization.txt(appName, "report", "common", "results", "excellent")
  }
}

export const getValueDescriptions = (showRange: boolean, localization: Localization, appName: string) => {
  const resultKey = showRange ? "results_with_range" : "results"

  return [
    localization.txt(appName, "report", "common", resultKey, "poor"),
    localization.txt(appName, "report", "common", resultKey, "satisfactory"),
    localization.txt(appName, "report", "common", resultKey, "fair"),
    localization.txt(appName, "report", "common", resultKey, "good"),
    localization.txt(appName, "report", "common", resultKey, "excellent")
  ]
}
