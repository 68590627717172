export const coreTranslations = {
  months: {
    january: {
      en: "January",
      fi: "Tammikuu",
      pl: "Styczeń",
      et: "Jaanuar",
      sv: "Januari",
      lv: "Sausis",
      lt: "Sausis",
      de: "Januar",
      es: "Enero",
      da: "Januar",
      no: "Januar",
      fr: "Janvier",
      pt: "Janeiro",
      zh: "一月",
      ja: "1月",
      sl: "Januar"
    },
    february: {
      en: "February",
      fi: "Helmikuu",
      pl: "Luty",
      et: "Vebruar",
      sv: "Februari",
      lv: "Februāris",
      lt: "Vasaris",
      de: "Februar",
      es: "Febrero",
      da: "Februar",
      no: "Februar",
      fr: "Février",
      pt: "Fevereiro",
      zh: "二月",
      ja: "2月",
      sl: "Februar"
    },
    march: {
      en: "March",
      fi: "Maaliskuu",
      pl: "Marzec",
      et: "Märts",
      sv: "Mars",
      lv: "Marts",
      lt: "Kovas",
      de: "März",
      es: "Marzo",
      da: "Marts",
      no: "Mars",
      fr: "Mars",
      pt: "Março",
      zh: "三月",
      ja: "3月",
      sl: "Marec"
    },
    april: {
      en: "April",
      fi: "Huhtikuu",
      pl: "Kwiecień",
      et: "Aprill",
      sv: "April",
      lv: "Aprīlis",
      lt: "Balandis",
      de: "April",
      es: "Abril",
      da: "April",
      no: "April",
      fr: "Avril",
      pt: "Abril",
      zh: "四月",
      ja: "4月",
      sl: "April"
    },
    may: {
      en: "May",
      fi: "Toukokuu",
      pl: "Maj",
      et: "Mai",
      sv: "Maj",
      lv: "Maijs",
      lt: "Gegužė",
      de: "Mai",
      es: "Mayo",
      da: "Maj",
      no: "Mai",
      fr: "Mai",
      pt: "Maio",
      zh: "五月",
      ja: "5月",
      sl: "Maj"
    },
    june: {
      en: "June",
      fi: "Kesäkuu",
      pl: "Czerwiec",
      et: "Juuni",
      sv: "Juni",
      lv: "Jūnijs",
      lt: "Birželis",
      de: "Juni",
      es: "Junio",
      da: "Juni",
      no: "Juni",
      fr: "Juin",
      pt: "Junho",
      zh: "六月",
      ja: "6月",
      sl: "Junij"
    },
    july: {
      en: "July",
      fi: "Heinäkuu",
      pl: "Lipiec",
      et: "Juuli",
      sv: "Juli",
      lv: "Jūlijs",
      lt: "Liepa",
      de: "Juli",
      es: "Julio",
      da: "Juli",
      no: "Juli",
      fr: "Juillet",
      pt: "Julho",
      zh: "七月",
      ja: "7月",
      sl: "Julij"
    },
    august: {
      en: "August",
      fi: "Elokuu",
      pl: "Sierpień",
      et: "August",
      sv: "Augusti",
      lv: "Augusts",
      lt: "Rugpjūtis",
      de: "August",
      es: "Agosto",
      da: "August",
      no: "August",
      fr: "Août",
      pt: "Agosto",
      zh: "八月",
      ja: "8月",
      sl: "Avgust"
    },
    september: {
      en: "September",
      fi: "Syyskuu",
      pl: "Wrzesień",
      et: "September",
      sv: "September",
      lv: "Septembris",
      lt: "Rugsėjis",
      de: "September",
      es: "Septiembre",
      da: "September",
      no: "September",
      fr: "Septembre",
      pt: "Setembro",
      zh: "九月",
      ja: "9月",
      sl: "September"
    },
    october: {
      en: "October",
      fi: "Lokakuu",
      pl: "Październik",
      et: "Oktoober",
      sv: "Oktober",
      lv: "Oktobris",
      lt: "Spalis",
      de: "Oktober",
      es: "Octubre",
      da: "Oktober",
      no: "Oktober",
      fr: "Octobre",
      pt: "Outubro",
      zh: "十月",
      ja: "10月",
      sl: "Oktober"
    },
    november: {
      en: "November",
      fi: "Marraskuu",
      pl: "Listopad",
      et: "November",
      sv: "November",
      lv: "Novembris",
      lt: "Lapkritis",
      de: "November",
      es: "Noviembre",
      da: "November",
      no: "November",
      fr: "Novembre",
      pt: "Novembro",
      zh: "十一月",
      ja: "11月",
      sl: "November"
    },
    december: {
      en: "December",
      fi: "Joulukuu",
      pl: "Grudzień",
      et: "Detsember",
      sv: "December",
      lv: "Decembris",
      lt: "Gruodis",
      de: "Dezember",
      es: "Diciembre",
      da: "December",
      no: "Desember",
      fr: "Décembre",
      pt: "Dezembro",
      zh: "十二月",
      ja: "12月",
      sl: "December"
    }
  },
  languages: {
    en: {
      name: {
        en: "English",
        fi: "English",
        pl: "English",
        et: "English",
        sv: "English",
        lv: "English",
        lt: "English",
        de: "English",
        es: "English",
        da: "English",
        no: "English",
        fr: "English",
        pt: "English",
        zh: "English",
        ja: "English",
        sl: "English"
      },
      short_name: {
        en: "EN",
        fi: "EN",
        pl: "EN",
        et: "EN",
        sv: "EN",
        lv: "EN",
        lt: "EN",
        de: "EN",
        es: "EN",
        da: "EN",
        no: "EN",
        fr: "EN",
        pt: "EN",
        zh: "EN",
        ja: "EN",
        sl: "EN"
      }
    },
    fi: {
      name: {
        en: "Suomi",
        fi: "Suomi",
        pl: "Suomi",
        et: "Suomi",
        sv: "Suomi",
        lv: "Suomi",
        lt: "Suomi",
        de: "Suomi",
        es: "Suomi",
        da: "Suomi",
        no: "Suomi",
        fr: "Suomi",
        pt: "Suomi",
        zh: "Suomi",
        ja: "Suomi",
        sl: "Suomi"
      },
      short_name: {
        en: "FI",
        fi: "FI",
        pl: "FI",
        et: "FI",
        sv: "FI",
        lv: "FI",
        lt: "FI",
        de: "FI",
        es: "FI",
        da: "FI",
        no: "FI",
        fr: "FI",
        pt: "FI",
        zh: "FI",
        ja: "FI",
        sl: "FI"
      }
    },
    sv: {
      name: {
        en: "Svenska",
        fi: "Svenska",
        pl: "Svenska",
        et: "Svenska",
        sv: "Svenska",
        lv: "Svenska",
        lt: "Svenska",
        de: "Svenska",
        es: "Svenska",
        da: "Svenska",
        no: "Svenska",
        fr: "Svenska",
        pt: "Svenska",
        zh: "Svenska",
        ja: "Svenska",
        sl: "Svenska"
      },
      short_name: {
        en: "SV",
        fi: "SV",
        pl: "SV",
        et: "SV",
        sv: "SV",
        lv: "SV",
        lt: "SV",
        de: "SV",
        es: "SV",
        da: "SV",
        no: "SV",
        fr: "SV",
        pt: "SV",
        zh: "SV",
        ja: "SV",
        sl: "SV"
      }
    },
    pl: {
      name: {
        en: "Polski",
        fi: "Polski",
        pl: "Polski",
        et: "Polski",
        sv: "Polski",
        lv: "Polski",
        lt: "Polski",
        de: "Polski",
        es: "Polski",
        da: "Polski",
        no: "Polski",
        fr: "Polski",
        pt: "Polski",
        zh: "Polski",
        ja: "Polski",
        sl: "Polski"
      },
      short_name: {
        en: "PL",
        fi: "PL",
        pl: "PL",
        et: "PL",
        sv: "PL",
        lv: "PL",
        lt: "PL",
        de: "PL",
        es: "PL",
        da: "PL",
        no: "PL",
        fr: "PL",
        pt: "PL",
        zh: "PL",
        ja: "PL",
        sl: "PL"
      }
    },
    ru: {
      name: {
        en: "Русский",
        fi: "Русский",
        pl: "Русский",
        et: "Русский",
        sv: "Русский",
        lv: "Русский",
        lt: "Русский",
        de: "Русский",
        es: "Русский",
        da: "Русский",
        no: "Русский",
        fr: "Русский",
        pt: "Русский",
        zh: "Русский",
        ja: "Русский",
        sl: "Русский"
      },
      short_name: {
        en: "RU",
        fi: "RU",
        pl: "RU",
        et: "RU",
        sv: "RU",
        lv: "RU",
        lt: "RU",
        de: "RU",
        es: "RU",
        da: "RU",
        no: "RU",
        fr: "RU",
        pt: "RU",
        zh: "RU",
        ja: "RU",
        sl: "RU"
      }
    },
    et: {
      name: {
        en: "Eesti",
        fi: "Eesti",
        pl: "Eesti",
        et: "Eesti",
        sv: "Eesti",
        lv: "Eesti",
        lt: "Eesti",
        de: "Eesti",
        es: "Eesti",
        da: "Eesti",
        no: "Eesti",
        fr: "Eesti",
        pt: "Eesti",
        zh: "Eesti",
        ja: "Eesti",
        sl: "Eesti"
      },
      short_name: {
        en: "ET",
        fi: "ET",
        pl: "ET",
        et: "ET",
        sv: "ET",
        lv: "ET",
        lt: "ET",
        de: "ET",
        es: "ET",
        da: "ET",
        no: "ET",
        fr: "ET",
        pt: "ET",
        zh: "ET",
        ja: "ET",
        sl: "ET"
      }
    },
    de: {
      name: {
        en: "Deutsch",
        fi: "Deutsch",
        pl: "Deutsch",
        et: "Deutsch",
        sv: "Deutsch",
        lv: "Deutsch",
        lt: "Deutsch",
        de: "Deutsch",
        es: "Deutsch",
        da: "Deutsch",
        no: "Deutsch",
        fr: "Deutsch",
        pt: "Deutsch",
        zh: "Deutsch",
        ja: "Deutsch",
        sl: "Deutsch"
      },
      short_name: {
        en: "DE",
        fi: "DE",
        pl: "DE",
        et: "DE",
        sv: "DE",
        lv: "DE",
        lt: "DE",
        de: "DE",
        es: "DE",
        da: "DE",
        no: "DE",
        fr: "DE",
        pt: "DE",
        zh: "DE",
        ja: "DE",
        sl: "DE"
      }
    },
    no: {
      name: {
        en: "Norsk",
        fi: "Norsk",
        pl: "Norsk",
        et: "Norsk",
        sv: "Norsk",
        lv: "Norsk",
        lt: "Norsk",
        de: "Norsk",
        es: "Norsk",
        da: "Norsk",
        no: "Norsk",
        fr: "Norsk",
        pt: "Norsk",
        zh: "Norsk",
        ja: "Norsk",
        sl: "Norsk"
      },
      short_name: {
        en: "NO",
        fi: "NO",
        pl: "NO",
        et: "NO",
        sv: "NO",
        lv: "NO",
        lt: "NO",
        de: "NO",
        es: "NO",
        da: "NO",
        no: "NO",
        fr: "NO",
        pt: "NO",
        zh: "NO",
        ja: "NO",
        sl: "NO"
      }
    },
    da: {
      name: {
        en: "Dansk",
        fi: "Dansk",
        pl: "Dansk",
        et: "Dansk",
        sv: "Dansk",
        lv: "Dansk",
        lt: "Dansk",
        de: "Dansk",
        es: "Dansk",
        da: "Dansk",
        no: "Dansk",
        fr: "Dansk",
        pt: "Dansk",
        zh: "Dansk",
        ja: "Dansk",
        sl: "Dansk"
      },
      short_name: {
        en: "DA",
        fi: "DA",
        pl: "DA",
        et: "DA",
        sv: "DA",
        lv: "DA",
        lt: "DA",
        de: "DA",
        es: "DA",
        da: "DA",
        no: "DA",
        fr: "DA",
        pt: "DA",
        zh: "DA",
        ja: "DA",
        sl: "DA"
      }
    },
    es: {
      name: {
        en: "Español",
        fi: "Español",
        pl: "Español",
        et: "Español",
        sv: "Español",
        lv: "Español",
        lt: "Español",
        de: "Español",
        es: "Español",
        da: "Español",
        no: "Español",
        fr: "Español",
        pt: "Español",
        zh: "Español",
        ja: "Español",
        sl: "Español"
      },
      short_name: {
        en: "ES",
        fi: "ES",
        pl: "ES",
        et: "ES",
        sv: "ES",
        lv: "ES",
        lt: "ES",
        de: "ES",
        es: "ES",
        da: "ES",
        no: "ES",
        fr: "ES",
        pt: "ES",
        zh: "ES",
        ja: "ES",
        sl: "ES"
      }
    },
    fr: {
      name: {
        en: "Français",
        fi: "Français",
        pl: "Français",
        et: "Français",
        sv: "Français",
        lv: "Français",
        lt: "Français",
        de: "Français",
        es: "Français",
        da: "Français",
        no: "Français",
        fr: "Français",
        pt: "Français",
        zh: "Français",
        ja: "Français",
        sl: "Français"
      },
      short_name: {
        en: "FR",
        fi: "FR",
        pl: "FR",
        et: "FR",
        sv: "FR",
        lv: "FR",
        lt: "FR",
        de: "FR",
        es: "FR",
        da: "FR",
        no: "FR",
        fr: "FR",
        pt: "FR",
        zh: "FR",
        ja: "FR",
        sl: "FR"
      }
    },
    pt: {
      name: {
        en: "Português",
        fi: "Português",
        pl: "Português",
        et: "Português",
        sv: "Português",
        lv: "Português",
        lt: "Português",
        de: "Português",
        es: "Português",
        da: "Português",
        no: "Português",
        fr: "Português",
        pt: "Português",
        zh: "Português",
        ja: "Português",
        sl: "Português"
      },
      short_name: {
        en: "PTT",
        fi: "PT",
        pl: "PT",
        et: "PT",
        sv: "PT",
        lv: "PT",
        lt: "PT",
        de: "PT",
        es: "PT",
        da: "PT",
        no: "PT",
        fr: "PT",
        pt: "PT",
        zh: "PT",
        ja: "PT",
        sl: "PT"
      }
    },
    zh: {
      name: {
        en: "中文",
        fi: "中文",
        pl: "中文",
        et: "中文",
        sv: "中文",
        lv: "中文",
        lt: "中文",
        de: "中文",
        es: "中文",
        da: "中文",
        no: "中文",
        fr: "中文",
        pt: "中文",
        zh: "中文",
        ja: "中文",
        sl: "中文"
      },
      short_name: {
        en: "ZH",
        fi: "ZH",
        pl: "ZH",
        et: "ZH",
        sv: "ZH",
        lv: "ZH",
        lt: "ZH",
        de: "ZH",
        es: "ZH",
        da: "ZH",
        no: "ZH",
        fr: "ZH",
        pt: "ZH",
        zh: "ZH",
        ja: "ZH",
        sl: "ZH"
      }
    },
    ja: {
      name: {
        en: "日本語",
        fi: "日本語",
        pl: "日本語",
        et: "日本語",
        sv: "日本語",
        lv: "日本語",
        lt: "日本語",
        de: "日本語",
        es: "日本語",
        da: "日本語",
        no: "日本語",
        fr: "日本語",
        pt: "日本語",
        zh: "日本語",
        ja: "日本語",
        sl: "日本語"
      },
      short_name: {
        en: "JA",
        fi: "JA",
        pl: "JA",
        et: "JA",
        sv: "JA",
        lv: "JA",
        lt: "JA",
        de: "JA",
        es: "JA",
        da: "JA",
        no: "JA",
        fr: "JA",
        pt: "JA",
        zh: "JA",
        ja: "JA",
        sl: "JA"
      }
    },
    sl: {
      name: {
        en: "Slovenščina",
        fi: "Slovenščina",
        pl: "Slovenščina",
        et: "Slovenščina",
        sv: "Slovenščina",
        lv: "Slovenščina",
        lt: "Slovenščina",
        de: "Slovenščina",
        es: "Slovenščina",
        da: "Slovenščina",
        no: "Slovenščina",
        fr: "Slovenščina",
        pt: "Slovenščina",
        zh: "Slovenščina",
        ja: "Slovenščina",
        sl: "Slovenščina"
      },
      short_name: {
        en: "SL",
        fi: "SL",
        pl: "SL",
        et: "SL",
        sv: "SL",
        lv: "SL",
        lt: "SL",
        de: "SL",
        es: "SL",
        da: "SL",
        no: "SL",
        fr: "SL",
        pt: "SL",
        zh: "SL",
        ja: "SL",
        sl: "SL"
      }
    }
  },
  core: {
    components: {
      settings: {
        SettingsSelector: {
          logout: {
            en: "Log out",
            fi: "Kirjaudu ulos",
            ru: "Выход",
            pl: "Wyloguj się",
            et: "Logi välja",
            sv: "Logga ut",
            lv: "Izrakstīties",
            lt: "Atsijungti",
            de: "Abmelden",
            es: "Cerrar sesión",
            da: "Log ud",
            no: "Logg ut",
            fr: "Déconnexion",
            pt: "Terminar sessão",
            zh: "登出",
            ja: "ログアウト",
            sl: "Odjava"
          }
        }
      },
      ui: {
        form: {
          done: {
            en: "Finish",
            fi: "Valmis",
            ru: "Готово",
            pl: "Koniec",
            et: "Valmis",
            sv: "Färdig",
            lv: "Gatavs",
            lt: "Pabaiga",
            de: "Beenden",
            no: "Ferdig",
            da: "Færdig",
            es: "Terminar",
            fr: "Terminer",
            pt: "Terminar",
            zh: "完成",
            ja: "終了",
            sl: "Končaj"
          },
          ok: {
            en: "OK",
            fi: "OK",
            ru: "OK",
            pl: "OK",
            et: "OK",
            sv: "OK",
            lv: "OK",
            lt: "OK",
            es: "OK",
            da: "OK",
            de: "OK",
            no: "OK",
            fr: "OK",
            pt: "OK",
            zh: "确定",
            ja: "OK",
            sl: "OK"
          },
          cancel: {
            en: "Cancel",
            fi: "Peruuta",
            ru: "Отмена",
            pl: "Anuluj",
            et: "Tühista",
            sv: "Avbryt",
            lv: "Atcelt",
            lt: "Atšaukti",
            de: "Abbrechen",
            es: "Cancelar",
            da: "Annuller",
            no: "Avbryt",
            fr: "Annuler",
            pt: "Cancelar",
            zh: "取消",
            ja: "キャンセル",
            sl: "Prekliči"
          },
          add: {
            en: "Add",
            fi: "Lisää",
            ru: "Добавить",
            pl: "Więcej",
            et: "Lisa",
            sv: "Lägg till",
            lv: "Pievienot",
            lt: "Pridėti",
            de: "Hinzufügen",
            es: "Añadir",
            da: "Tilføj",
            no: "Legg til",
            fr: "Ajouter",
            pt: "Adicionar",
            zh: "添加",
            ja: "追加",
            sl: "Dodaj"
          },
          edit: {
            en: "Edit",
            fi: "Muokkaa",
            ru: "Изменить",
            pl: "Edytuj",
            et: "Muuda",
            sv: "Redigera",
            lv: "Rediģēt",
            lt: "Redaguoti",
            de: "Bearbeiten",
            es: "Editar",
            da: "Rediger",
            no: "Rediger",
            fr: "Modifier",
            pt: "Editar",
            zh: "编辑",
            ja: "編集",
            sl: "Uredi"
          },
          update: {
            en: "Update",
            fi: "Päivitä",
            ru: "Обновить",
            pl: "Odśwież",
            et: "Uuenda",
            sv: "Uppdatera",
            lv: "Atjaunināt",
            lt: "Atnaujinti",
            de: "Aktualisieren",
            es: "Actualizar",
            da: "Opdater",
            no: "Oppdater",
            fr: "Mettre à jour",
            pt: "Atualizar",
            zh: "更新",
            ja: "更新",
            sl: "Posodobi"
          },
          remove: {
            en: "Remove",
            fi: "Poista",
            ru: "Удалить",
            pl: "Usuń",
            et: "Kustuta",
            sv: "Ta bort",
            lv: "Dzēst",
            lt: "Pašalinti",
            de: "Entfernen",
            es: "Eliminar",
            da: "Fjern",
            no: "Fjern",
            fr: "Supprimer",
            pt: "Remover",
            zh: "删除",
            ja: "削除",
            sl: "Odstrani"
          },
          start: {
            en: "Start",
            fi: "Aloita",
            ru: "Начать",
            pl: "Rozpocznij",
            et: "Alusta",
            sv: "Börja",
            lv: "Sākt",
            lt: "Pradėti",
            de: "Starten",
            es: "Comenzar",
            da: "Start",
            no: "Start",
            fr: "Démarrer",
            pt: "Começar",
            zh: "开始",
            ja: "開始",
            sl: "Začni"
          },
          next: {
            en: "Next",
            fi: "Seuraava",
            ru: "Далее",
            pl: "Dalej",
            et: "Järgmine",
            sv: "Nästa",
            lv: "Nākošais",
            lt: "Kitas",
            de: "Weiter",
            no: "Neste",
            da: "Næste",
            es: "Siguiente",
            fr: "Suivant",
            pt: "Seguinte",
            zh: "下一步",
            ja: "次へ",
            sl: "Naslednji"
          },
          previous: {
            en: "Previous",
            fi: "Edellinen",
            ru: "Назад",
            pl: "Wstecz",
            et: "Eelmine",
            sv: "Föregående",
            lv: "Iepriekšējais",
            lt: "Ankstesnis",
            de: "Zurück",
            no: "Forrige",
            da: "Forrige",
            es: "Anterior",
            fr: "Précédent",
            pt: "Anterior",
            zh: "上一步",
            ja: "前へ",
            sl: "Prejšnji"
          },
          close: {
            en: "Close",
            fi: "Sulje",
            ru: "Закрыть",
            pl: "Zamknij",
            et: "Sulge",
            sv: "Stäng",
            lv: "Aizvērt",
            lt: "Uždaryti",
            de: "Schließen",
            es: "Cerrar",
            da: "Luk",
            no: "Lukk",
            fr: "Fermer",
            pt: "Fechar",
            zh: "关闭",
            ja: "閉じる",
            sl: "Zapri"
          },
          back: {
            en: "Back",
            fi: "Palaa",
            ru: "Назад",
            pl: "Wstecz",
            et: "Tagasi",
            sv: "Tillbaka",
            lv: "Atgriezties",
            lt: "Atgal",
            de: "Zurück",
            es: "Atrás",
            da: "Tilbage",
            no: "Tilbake",
            fr: "Retour",
            pt: "Anterior",
            zh: "返回",
            ja: "戻る",
            sl: "Nazaj"
          },
          return: {
            en: "Return",
            fi: "Palaa",
            ru: "Вернуться",
            pl: "Wróć",
            et: "Tagasi",
            sv: "Tillbaka",
            lv: "Atgriezties",
            lt: "Grįžti",
            de: "Zurück",
            es: "Volver",
            da: "Tilbage",
            no: "Gå tilbake",
            fr: "Retour",
            pt: "Voltar",
            zh: "返回",
            ja: "戻る",
            sl: "Povratek"
          },
          continue: {
            en: "Continue",
            fi: "Jatka",
            ru: "Продолжить",
            pl: "Kontynuuj",
            et: "Jätka",
            sv: "Fortsätt",
            lv: "Turpināt",
            lt: "Tęsti",
            de: "Weiter",
            es: "Continuar",
            da: "Fortsæt",
            no: "Fortsett",
            fr: "Continuer",
            pt: "Continuar",
            zh: "继续",
            ja: "続ける",
            sl: "Nadaljuj"
          },
          show: {
            en: "Show",
            fi: "Näytä",
            ru: "Показать",
            pl: "Pokaż",
            et: "Näita",
            sv: "Visa",
            lv: "Rādīt",
            lt: "Rodyti",
            de: "Anzeigen",
            es: "Mostrar",
            da: "Vis",
            no: "Vis",
            fr: "Afficher",
            pt: "Mostrar",
            zh: "显示/展开",
            ja: "表示",
            sl: "Pokaži"
          },
          hide: {
            en: "Hide",
            fi: "Piilota",
            ru: "Скрыть",
            pl: "Ukryj",
            et: "Peida",
            sv: "Dölj",
            lv: "Paslēpt",
            lt: "Paslėpti",
            de: "Ausblenden",
            es: "Ocultar",
            da: "Skjul",
            no: "Skjul",
            fr: "Masquer",
            pt: "Ocultar",
            zh: "隐藏/收起",
            ja: "非表示",
            sl: "Skrij"
          },
          save: {
            en: "Save",
            fi: "Tallenna",
            ru: "Сохранить",
            pl: "Zapisz",
            et: "Salvesta",
            sv: "Spara",
            lv: "Saglabāt",
            lt: "Išsaugoti",
            de: "Speichern",
            es: "Guardar",
            da: "Gem",
            no: "Lagre",
            fr: "Enregistrer",
            pt: "Guardar",
            zh: "保存",
            ja: "保存",
            sl: "Shrani"
          },
          preview: {
            en: "Preview",
            fi: "Esikatsele",
            ru: "Предпросмотр",
            pl: "Podgląd",
            et: "Eelvaade",
            sv: "Förhandsgranska",
            lv: "Priekšskatīt",
            lt: "Peržiūrėti",
            de: "Voransicht",
            es: "Vista previa",
            da: "Forhåndsvisning",
            no: "Forhåndsvis",
            fr: "Aperçu",
            pt: "Pré-visualizar",
            zh: "预览",
            ja: "プレビュー",
            sl: "Predogled"
          },
          login: {
            en: "Login",
            fi: "Kirjaudu sisään",
            ru: "Вход",
            pl: "Zaloguj się",
            et: "Logi sisse",
            sv: "Logga in",
            lv: "Pierakstīties",
            lt: "Prisijungti",
            de: "Anmelden",
            no: "Logg Inn",
            da: "Log på",
            es: "Iniciar sesión",
            fr: "Connexion",
            pt: "Iniciar sessão",
            zh: "登录",
            ja: "ログイン",
            sl: "Prijava"
          },
          logout: {
            en: "Log out",
            fi: "Kirjaudu ulos",
            ru: "Выход",
            pl: "Wyloguj się",
            et: "Logi välja",
            sv: "Logga ut",
            lv: "Izrakstīties",
            lt: "Atsijungti",
            de: "Abmelden",
            no: "Logg ut",
            da: "Log ud",
            es: "Cerrar sesión",
            fr: "Déconnexion",
            pt: "Terminar sessão",
            zh: "登出",
            ja: "ログアウト",
            sl: "Odjava"
          }
        }
      }
    }
  },
  errors: {
    email_not_found: {
      en: "Email not found",
      fi: "Sähköpostia ei löydy",
      ru: "Электронная почта не найдена",
      pl: "Nie znaleziono adresu e-mail",
      et: "E-posti ei leitud",
      sv: "E-postadressen hittades inte",
      lv: "E-pasts nav atrasts",
      lt: "Elektroninio pašto nerasta",
      de: "E-Mail nicht gefunden",
      da: "E-mail ikke fundet",
      no: "E-postadressen ble ikke funnet",
      fr: "Adresse e-mail non trouvée",
      pt: "E-mail não encontrado",
      zh: "电子邮件地址未找到",
      ja: "メールが見つかりません",
      sl: "E-pošta ni bila najdena"
    }
  }
}
