import get from "lodash/get"
import moment from "moment"

import { type LocalizationConfig, baseLocalizationConfig } from "core/modules/config/localizationConfig"
import type { TranslatedString, Translations } from "core/modules/state/model/Model"

export type TranslationParams = Record<string, string | number> | (string | number)[]
export type TranslationKey = string | undefined | TranslatedString | TranslationParams

export class I18n {
  static config: LocalizationConfig = baseLocalizationConfig
  static currentLanguage = "en"
  static translations: Translations = {}

  static setup(config: LocalizationConfig = baseLocalizationConfig) {
    I18n.config = config

    moment.locale(I18n.defaultLanguage)
  }

  static addTranslations(translations: Translations) {
    I18n.translations = { ...I18n.translations, ...translations }
  }

  static get defaultLanguage() {
    return I18n.config.defaultLanguage
  }

  static get enabledLanguages() {
    return I18n.config.enabledLanguages
  }

  static txt = (...keys: TranslationKey[]): string => {
    // Last key can be a parameter object or array for template
    const params = keys.length > 1 && typeof keys[keys.length - 1] === "object" ? keys.pop() : null

    let template = ""

    if (typeof keys[0] === "object") {
      template = I18n.txtWithTranslatedString(keys[0] as TranslatedString)
    } else if (typeof keys[0] === "string") {
      template = I18n.txtWithKeys(...(keys as string[]))
    }

    if (template && params) {
      for (const [search, replace] of Object.entries(params)) {
        template = template.replace(`{${search}}`, String(replace))
      }
    }

    return template
  }

  static txtWithLanguage = (language: string) => {
    I18n.currentLanguage = language

    return I18n.txt
  }

  private static txtWithKeys = (...keys: string[]): string => {
    const translations = get(I18n.translations, keys, {})

    const found = [I18n.currentLanguage, I18n.defaultLanguage].find(l => !!translations[l])

    return found ? translations[found] : [...keys, I18n.currentLanguage].join(".")
  }

  private static txtWithTranslatedString = (key: TranslatedString) => {
    // The translations are held within the object in a structure { "language-code": "translation" }
    const language = [I18n.currentLanguage, I18n.defaultLanguage].find(l => !!key[l])

    return (language && key[language]) || ""
  }
}
