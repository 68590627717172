import { ModelManager } from "core/modules/state/model/ModelManager"
import moment from "moment"
import { BaseModule } from "../../../controller/Module"
import { AccessToken, AuthenticationState, Model, TokenType } from "../model/Model"

/**
 * The initial redux state
 */
export class InitialStateModule extends BaseModule {
  get moduleName() {
    return "InitialStateModule"
  }

  get dependencies() {
    return ["ModelManager"]
  }

  get setupPriority() {
    return -2000
  }

  declare modelManager: ModelManager

  setup() {
    const authenticationState: Partial<AuthenticationState> = {
      __type: "AuthenticationState",
      loggedIn: false
    }

    if (localStorage.getItem("authentication_access_token")) {
      this.logger.info("Remember me data found from local storage. Using it to log in.")
      authenticationState.loggedIn = true
      const accessToken: AccessToken = {
        __type: "AccessToken",
        access_token: localStorage.getItem("authentication_access_token")!,
        created_at: moment.utc(),
        id: "-2",
        refresh_token: "",
        scope: localStorage.getItem("authentication_token_scope")!,
        token_type: <TokenType>localStorage.getItem("authentication_token_type")
      }

      this.coreActions.setDocumentLocal(accessToken)
    } else {
      // Don't remember selected organisation if we don't remember access token
      localStorage.removeItem("selectedOrganisation")
    }

    this.coreActions.setDefaultDocumentLocal<AuthenticationState>(authenticationState)
  }

  getInitialState(): Model {
    return {
      documents: {
        AuthenticationState: { __type: <string>"AuthenticationState" },
        AccessToken: { __type: "AccessToken" }
      },
      views: {}
    }
  }
}
