import { BaseModuleWithAppName } from "core/controller/Module"
import type { LocalizationConfig } from "core/modules/config/localizationConfig"
import { I18n, type TranslationKey } from "core/modules/localization/I18n"
import { LocalizationState } from "core/modules/state/model/Model"
import { ModelManager } from "core/modules/state/model/ModelManager"

export interface Localization extends LocalizationConfig {
  currentLanguage: string

  txt(...keys: TranslationKey[]): string
}

export class LocalizationModule extends BaseModuleWithAppName implements Localization {
  declare modelManager: ModelManager

  get moduleName() {
    return "Localization"
  }

  get dependencies() {
    return ["ModelManager"]
  }

  get currentLanguage() {
    return (
      this.modelManager.getDefaultDocument<LocalizationState>("LocalizationState")?.language || I18n.defaultLanguage
    )
  }

  get defaultLanguage() {
    return I18n.defaultLanguage
  }

  get dateFormat() {
    return I18n.config.dateFormat
  }

  get dateTimeFormat() {
    return I18n.config.dateTimeFormat
  }

  get decimalSeparator() {
    return I18n.config.decimalSeparator
  }

  get enabledLanguages() {
    return I18n.config.enabledLanguages
  }

  get surveyLanguages() {
    return I18n.config.surveyLanguages
  }

  txt = (...keys: TranslationKey[]): string => {
    return I18n.txtWithLanguage(this.currentLanguage)(...keys)
  }
}
