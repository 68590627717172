import type { RequireKeys } from "lib/utils/TypeUtils"

/**
 * Identifier for the special default document for each collection
 */
export const defaultDocumentId = "-1"

/**
 * Data model.
 */
export interface Model {
  documents: Documents
  views: Views
  modifyCount: number
  idCounter: number
}

/**
 * All documents by document type
 */
export interface Documents {
  LocalizationState?: DocumentContainer<LocalizationState>
}

/**
 * Views that link to documents.
 */
export interface Views {}

// *** Internal document types ***

// Basic user information
export interface User extends Doc {
  email: string
}

export interface LocalizationState extends Doc {
  language: string
}

// HTTP API Error result
export interface HttpError {
  error: string
  httpStatusCode?: number
  error_description?: string
}

// *** Model base interfaces ***

/**
 * Properties shared by all documents in model
 */
export interface Doc {
  __type: string
  __deleted?: boolean
  id: string
  [key: string]: unknown
}

/**
 * Container structure in the model. Contains both dictionary and array for contained documents.
 */
export interface DocumentContainer<T> {
  __type: string
  dictionary?: { [id: string]: T }
  apiPath?: string
  modifyCount?: number
}

/**
 * Object linking to a document
 */
export type Link<T extends Doc = Doc> = Pick<T, "id" | "__type">

/**
 * View that contains links to documents. Provides a filtered and sorted access to documents.
 */
export interface ViewContainer {
  array: Link[]
  documentType: string
  viewName: string
  parameters?: any
  createdAt?: Date
  updatedAt?: Date
  modifyCount?: number
  raw?: any
}

export interface TranslatedString {
  [key: string]: string
}

export interface Translations {
  [keyOrLanguage: string]: string | Translations
}
