import { useCallback } from "react"

import { useAppName } from "core/controller/useAppName"
import { I18n } from "core/modules/localization/I18n"
import type { LocalizationState } from "core/modules/state/model/Model"
import { useDefaultDocument } from "core/modules/store/useDefaultDocument"

export const useCurrentLanguage = (): [string, (language: string) => void] => {
  const [localization, setLocalization] = useDefaultDocument<LocalizationState>("LocalizationState")

  const setLanguage = useCallback((language: string) => setLocalization({ ...localization, language }), [localization])

  return [localization.language || I18n.defaultLanguage, setLanguage]
}

export const useTranslate = (language?: string) => {
  const [currentLanguage] = useCurrentLanguage()

  return I18n.txtWithLanguage(language || currentLanguage)
}

export const useTranslateWithBaseKey = (...baseKey: string[]) => {
  const appName = useAppName()

  return useTranslateWithRootKey(appName, ...baseKey)
}

export const useTranslateWithRootKey = (...rootKey: string[]) => {
  const translate = useTranslate()

  return (...key: Parameters<typeof translate>) => translate(...rootKey, ...key)
}
