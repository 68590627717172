export const obfuscate = (str: string, cipher: string): string => {
  const cipherBytes = new TextEncoder().encode(cipher)
  const cipherLength = cipherBytes.length

  const obfuscated = Array.from(new TextEncoder().encode(str))
    .map((byte, i) => String.fromCharCode(byte + cipherBytes[i % cipherLength]))
    .join("")

  return btoa(obfuscated).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "")
}

export const obfuscateObject = <T = any>(obj: T, cipher: string): string => {
  return obfuscate(JSON.stringify(obj), cipher)
}

export const deobfuscate = (str: string, cipher: string): string => {
  const cipherBytes = new TextEncoder().encode(cipher)
  const cipherLength = cipherBytes.length

  const deobfuscated = atob(str.replace(/-/g, "+").replace(/_/g, "/"))
    .split("")
    .map((char, i) => String.fromCharCode(char.charCodeAt(0) - cipherBytes[i % cipherLength]))
    .join("")

  return new TextDecoder().decode(new Uint8Array(deobfuscated.split("").map(char => char.charCodeAt(0))))
}

export const deobfuscateObject = <T = any>(str: string, cipher: string): T | null => {
  try {
    return JSON.parse(deobfuscate(str, cipher))
  } catch {
    return null
  }
}
