import { deobfuscateObject } from "lib/utils/StringObfuscator"
import posthog from "posthog-js"
import shortid from "shortid"

import { Store } from "core/modules/store/Store"
import { RethrowExceptions } from "lib/utils/ErrorUtils"

import { BaseModule } from "../../controller/Module"
import { OauthApi } from "../api/OauthApi"
import { AccessToken, AuthenticationState } from "../state/model/Model"

export interface AuthenticationActions {
  loginWithToken(
    token: string,
    email: string,
    rememberMe?: boolean,
    mfaToken?: string,
    scope?: string
  ): Promise<Response>
  loginWithObfuscatedCredentials(credentials: string): Promise<Response>
  logout(): Promise<boolean>
  resetTokenLoginState(): void
}

interface LoginParameters {
  token: string
  mfaToken?: string
  scope?: string
  email: string
  rememberMe?: boolean
}

export class AuthenticationActionsModule extends BaseModule implements AuthenticationActions {
  declare oauthApi: OauthApi
  declare store: Store

  get moduleName() {
    return "AuthenticationActions"
  }

  get dependencies() {
    return ["Store", "OauthApi"]
  }

  // @ts-ignore
  @RethrowExceptions
  async loginWithToken(
    token: string,
    email: string,
    rememberMe?: boolean,
    mfaToken?: string,
    scope?: string
  ): Promise<Response> {
    this.logger.info("Login with token. Remember me:", rememberMe)
    posthog.capture("login_with_token")

    return this.login({ token, email, rememberMe, mfaToken, scope })
  }

  async loginWithObfuscatedCredentials(credentials: string) {
    this.logger.info("Login with obfuscated credentials.")
    posthog.capture("login_with_obfuscated_credentials")

    const deobfuscatedCredentials = deobfuscateObject<{ e: string; t: string }>(credentials, "email")
    if (!deobfuscatedCredentials?.e || !deobfuscatedCredentials?.t) {
      throw new Error("invalid_obfuscated_credentials")
    }

    const { e: email, t: token } = deobfuscatedCredentials

    return this.login({ email, token })
  }

  // @ts-ignore
  @RethrowExceptions
  async login(loginParameters: LoginParameters): Promise<Response> {
    const { token, mfaToken, scope, email, rememberMe } = loginParameters

    const response = await this.oauthApi.loginWithToken(token, email ?? "", mfaToken ?? "", scope)

    if (response.ok) {
      const json = await response.json()

      json.id = shortid.generate()

      posthog.capture("login_with_token_successful")

      const accessToken = this.coreActions.importDocument<AccessToken>(json, "AccessToken")
      this.oauthApi.accessToken = accessToken

      if (rememberMe && accessToken) {
        this.logger.info("Remembering user, saving access token", {
          scope: accessToken.scope,
          tokenType: accessToken.token_type
        })
        localStorage.setItem("authentication_access_token", accessToken.access_token)
        localStorage.setItem("authentication_token_scope", accessToken.scope)
        localStorage.setItem("authentication_token_type", accessToken.token_type)
      }

      const authenticationState = Object.assign(
        {},
        this.coreActions.getDefaultDocumentLocal<AuthenticationState>("AuthenticationState")
      )

      authenticationState.loggedIn = true
      authenticationState.tokenLoginError = undefined
      this.coreActions.setDefaultDocumentLocal(authenticationState)

      return response
    } else {
      const json = await response.json()
      const error = this.oauthApi.getHttpError(response.status, json)
      posthog.capture("login_with_token_failed", error)
      throw this.oauthApi.getHttpError(response.status, json)
    }
  }

  async logout() {
    this.logger.info("Logging out")

    localStorage.clear()

    posthog.capture("logout")

    const response = await this.oauthApi.logout()

    if (response.ok) {
      this.logger.info("Logged out from remote system")
      return true
    } else {
      this.logger.warning("Logging out from remote system failed.")
      return false
    }
  }

  resetTokenLoginState() {
    const authenticationState = this.coreActions.getDefaultDocumentLocal<AuthenticationState>("AuthenticationState")
    if (authenticationState) {
      authenticationState.loggedIn = false
      authenticationState.tokenLoginError = undefined
      this.coreActions.setDefaultDocumentLocal(authenticationState)
    }

    if (this.oauthApi.accessToken) {
      this.coreActions.removeDocumentLocal(this.oauthApi.accessToken)
    }
  }
}
