// Used currently to work around safari layout bugs. For this reason we don't want
// to use feature detection since we cannot easily detect these Safari bugs.
// Code based on:
// https://stackoverflow.com/questions/7944460/detect-safari-browser/23522755 (qingu answer)
export const isSafari = () =>
  navigator.vendor?.includes("Apple") &&
  !navigator.userAgent?.includes("CriOS") &&
  !navigator.userAgent?.includes("FxiOS")

export const MOBILE_BREAKPOINT = 600

export const isMobile = () => screen.width <= MOBILE_BREAKPOINT
